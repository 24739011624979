
import PrismicSlice from './PrismicSlice.vue'

export default {
  name: 'LandingPage',
  components: { PrismicSlice },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    safeToRenderSlices() {
      return (
        (this.document && this.document.data && this.document.data.body) ||
        []
      ).filter(
        (slice) =>
          slice.id && slice.slice_type && (slice.primary || slice.items)
      )
    },
  },
}
