
import { DEFAULT_LANDING_PAGE_TITLE } from '../lib/seo'
import LandingPage from '~/components/LandingPage.vue'

export default {
  components: { LandingPage },
  async asyncData({ $prismic, error }) {
    try {
      const document = await $prismic.api.getByUID(
        'landing_page',
        'marketing-homepage',
        {
          fetchLinks: [
            'team_member.name',
            'team_member.bio',
            'team_member.linkedin',
            'team_member.portrait',
            'team_member.attribution',
          ],
        }
      )
      return {
        document,
      }
    } catch (e) {
      if (process.browser) {
        alert(e)
      } else {
        console.error(e)
      }
      error({ statusCode: 404, message: 'Page not found' })
    }
  },
  head() {
    return {
      title:
        (this.document &&
          this.document.data &&
          this.document.data.page_title) ||
        DEFAULT_LANDING_PAGE_TITLE,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            (this.document &&
              this.document.data &&
              this.document.data.meta_description) ||
            'The hiring platform for building high-impact boards. Reduce the risk of missing out on the non-executive talent your board needs. Create a powerful, diverse shortlist of exceptional candidates, invisible to traditional headhunting.',
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content:
            (this.document &&
              this.document.data &&
              this.document.data.meta_description) ||
            'The hiring platform for building high-impact boards. Reduce the risk of missing out on the non-executive talent your board needs. Create a powerful, diverse shortlist of exceptional candidates, invisible to traditional headhunting.',
        },
      ],
    }
  },
}
