
export default {
  name: 'PrismicSlice',
  props: {
    slice: {
      type: Object,
      default: Function,
    },
  },
  computed: {
    sliceComponent() {
      switch (this.slice.slice_type) {
        case 'hero_image_with_dual_ctas':
          return 'HeroImageWithDualCtas'
        case 'hero_image_with_optional_cta':
          return 'HeroImageWithOptionalCta'
        case 'introduction_with_benfits':
          return 'IntroductionWithBenefits'
        case 'hubspot_form':
          return 'HubspotForm'
        case 'free_content':
          return 'FreeContent'
        case 'meeting_calendar':
          return 'MeetingCalendar'
        case 'image_with_text':
          return 'ImageWithText'
        case 'columns':
          return 'Columns'
        case 'organisation_type_picker':
          return 'OrganisationTypePicker'
        // case 'latest_news':
        //   return 'LatestNews'
        // case 'success_stories':
        //   return 'SuccessStories'
        case 'logo_grid':
          return 'LogoGrid'
        case 'how_it_works':
          return 'HowItWorks'
        case 'how_to_find_candidates':
          return 'HowToFindCandidates'
        case 'challenge_us':
          return 'Deprecated'
        case 'faq_search':
          return 'Deprecated'
        case 'cta':
          return 'CallToAction'
        case 'full_width_expanding':
          return 'FullWidthExpanding'
        // case 'video':
        //   return 'Video'
        case 'dual_cta':
          return 'DualCTA'
        case 'placement_statistics':
          return 'PlacementStatistics'
        case 'hero_block_quote':
          return 'HeroBlockWithQuote'
        case 'numbers_three_columns':
          return 'NumbersThreeColumns'
        case 'hero_headshots':
          return 'HeroHeadshots'
        case 'process':
          return 'Process'
        case 'transparency':
          return 'Transparency'
        case 'diversity':
          return 'Diversity'
        case 'why_use_nurole':
          return 'WhyUseNurole'
        case 'team_members':
          return 'TeamMembers'
        case 'hero_image_with_header':
          return 'HeroImageWithHeader'
        case 'advantages_quadrant':
          return 'AdvantagesQuadrant'
        case 'text_with_sidebar':
          return 'TextWithSidebar'
        case 'quotes_with_headshots':
          return 'QuotesWithHeadshots'
        case 'image_and_text':
          return 'ImageAndText'
        case 'rich_text':
          return 'RichText'
        case 'team':
          return 'TeamBySector'
        case 'success_story_quote':
          return 'SuccessStoryQuote'
        case 'video':
          return 'Video'
        case 'member_services_profile':
          return 'MemberServicesProfiles'
        default:
          return 'SliceNotFound'
      }
    },
  },
}
